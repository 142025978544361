﻿import { Component, ViewChild, OnInit, EventEmitter } from '@angular/core';
import { HttpService } from '../..//./services/http.service';
import { NgForm } from '@angular/forms'; 
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { UserModel } from "./..//../models/user.model";
function _window(): any {
    return window;
}

@Component({
    selector: 'user-save',
    templateUrl: './user-save.component.html',
    providers: [HttpService]
})
export class UserSaveComponent implements OnInit {
    @ViewChild('myForm') public myForm: NgForm;
    id: number = 0;
    api = { "Save": "userdetail/save", "Find": "userdetail/find", "UserRoles": "masterdata/userroles" };
    obj: UserModel = new UserModel();
    isSubmitted: boolean = false;
    isLoader: boolean = false;
    header: string = "";
    activeTab = "UserBasic";
    userRoles: any = [];
    occupation_create: number = 0;
    address_create: number = 0;
    social_media_create: number = 0;
    followup_create: number = 0;
    constructor(public http: HttpService, public router: Router, public toastr: ToastrService) {
        if (this.myForm) {
            this.myForm.reset();
        }
    }


    get window(): any {
        return _window();
    }

    ngOnInit(): void {
        if (this.myForm) {
            this.myForm.reset();
        }
        this.bindUserRoles();
        if (this.window.id && parseInt(this.window.id) > 0) {
            this.id = parseInt(this.window.id);
            this.clear();
            this.header = "Update User";
            this.find();
        }
        else {
            this.header = "Create User";
        }
    }

    clear() {
        this.isSubmitted = false;
        this.isLoader = false;
        if (this.myForm) {
            this.myForm.reset();
        }
        this.obj = new UserModel();
    }

    save() {
        this.isSubmitted = true;
        if (this.myForm.valid) {
            this.obj.UserStatus = "User";
            this.isLoader = true;
            this.http.post("" + this.api.Save, this.obj).subscribe(result => {
                if (result.json().IsFailure) {
                    this.toastr.error('Failed!', result.json().Message);
                }
                else {
                    this.toastr.success('Success!', result.json().Message);
                    if (this.obj.Id <= 0) {
                        //this.clear();
                        this.router.navigateByUrl("/users");
                    }
                }
                this.isLoader = false;
            }, error => {
                this.isLoader = false;
                this.http.validateAuth(error);
            });
        }
    }


    find() {
        this.obj = new UserModel();
        this.isLoader = true;
        if (this.id > 0) {
            this.http.post("" + this.api.Find, { Id: this.id }).subscribe(result => {
                if (!result.json().IsFailure) {
                    this.obj = result.json();
                }
                this.isLoader = false;
            }, error => {
                this.isLoader = false;
                this.http.validateAuth(error);
            });
        }
    }

    bindUserRoles() {
        this.http.post("" + this.api.UserRoles).subscribe(result => {
            this.userRoles = result.json();
        }, error => {
            this.http.validateAuth(error);
        });
    }
}