﻿import { Component, EventEmitter, Output } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { ToastrService } from 'ngx-toastr';

declare var getLocalStorage: any;
declare var setLocalStorage: any;
function _window(): any {
    return window;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    providers: [HttpService]
})
export class HeaderComponent {
    
    userName: string = "";
    isLoader: boolean = false;
    @Output('isauth') isauth: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(public http: HttpService, public toastr: ToastrService) {
        this.userName = getLocalStorage("_rfnm");
    }
    get window(): any {
        return _window();
    }
    

    signout() {
        this.isLoader = false;
        this.http.post("auth/logout").subscribe(result => {
            setLocalStorage("_rfcc", "");
            setLocalStorage("_rfnm", "");
            this.userName = "";
            this.isauth.emit(false);
            this.isLoader = true;
            this.window.name = "";
        }, error => {
            this.isLoader = false;
        });
        
    }

    
}