﻿export class UserAddressModel {
    Id: number = 0;
    UserId: number = 0;
    UserOccupationId: number = 0;
    AddressType: string = "";
    AddressLineOne: string = "";
    AddressLineTwo: string = "";
    City: string = "";
    Province: string = "";
    Country: string = "United States of America";
    Latitude: string = "";
    Longitude: string = "";
    PostalCode: string = "";
    IsDefault?: boolean = false;
}