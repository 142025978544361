﻿import { Component, Output, EventEmitter, ViewChild, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { PagerService } from '../..//./services/pager.service';
import { HttpService } from '../..//./services/http.service';
import { Router } from "@angular/router";
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FollowupModel } from "./..//../models/followup.model";
function _window(): any {
    return window;
}

@Component({
    selector: 'follow-up-save',
    templateUrl: './followup-save.component.html',
    providers: [PagerService, HttpService]
})
export class FollowupSaveComponent implements OnInit {
    @ViewChild('myForm') public myForm: NgForm;
    id: number = 0;
    api = { "Save": "followup/save", "Find": "followup/find", "SocialMedia": "usersocialmedia/lookup", "Users": "userdetail/lookup" };
    obj: FollowupModel = new FollowupModel();
    isSubmitted: boolean = false;
    isLoader: boolean = false;
    header: string = "";
    socialMedia: any = [];
    users: any = [];
    todayDate: any = new Date();
    constructor(public http: HttpService, public pagerService: PagerService, public router: Router, public toastr: ToastrService) {

        if (this.myForm) {
            this.myForm.reset();
        }
    }
    get window(): any {
        return _window();
    }
    ngOnInit(): void {
        if (this.myForm) {
            this.myForm.reset();
        }
        this.bindUsers();
        if (this.window.id && parseInt(this.window.id) > 0) {
            this.id = parseInt(this.window.id);
            this.clear();
            this.header = "Update Followup";
            this.find();
        }
        else {
            this.header = "Create Followup";
        }

    }

    clear() {
        this.isSubmitted = false;
        this.isLoader = false;
        if (this.myForm) {
            this.myForm.reset();
        }
        this.obj = new FollowupModel();
    }


    find() {
        this.obj = new FollowupModel();
        if (this.id > 0) {
            this.http.post("" + this.api.Find, { Id: this.id }).subscribe(result => {
                if (!result.json().IsFailure) {
                    this.obj = result.json();

                    this.bindSocialMedia();
                }
                this.isLoader = false;
            }, error => {
                this.isLoader = false;
                this.http.validateAuth(error);
            });
        }
    }

    save() {
        this.isSubmitted = true;
        if (this.myForm.valid) {
            this.isLoader = true;
            this.http.post("" + this.api.Save, this.obj).subscribe(result => {
                if (result.json().IsFailure) {
                    this.toastr.error('Failed!', result.json().Message);
                }
                else {
                    this.toastr.success('Success!', result.json().Message);
                    if (this.obj.Id <= 0) {
                        //this.clear();
                        this.router.navigateByUrl("/follow-ups");
                    }
                }
                this.isLoader = false;
            }, error => {
                this.isLoader = false;
                this.http.validateAuth(error);
            });
        }
    }

    bindUsers() {
        this.http.post("" + this.api.Users).subscribe(result => {
            this.users = result.json();
        }, error => {
            this.http.validateAuth(error);
        });
    }

    bindUserStatus() {
        if (this.obj.UserId > 0) {
            for (let i = 0; i < this.users.length; i++)
            {
                if (this.users[i].Id == this.obj.UserId)
                {
                    this.obj.UserStatus = this.users[i].UserStatus;
                }
            }
        }
    }

    bindSocialMedia() {
        if (this.obj.UserId > 0) {
            this.http.post("" + this.api.SocialMedia).subscribe(result => {
                this.socialMedia = result.json();
                this.bindThrough();
                this.bindUserStatus();
            }, error => {
                this.http.validateAuth(error);
            });
        }
    }

    bindThrough() {
        if (this.obj.FollowUpProcess != "") {
            if (this.obj.FollowUpProcess == "Primary Email") {
                for (let i = 0; i < this.users.length; i++) {
                    if (this.users[i].Id == this.obj.UserId) {
                        this.obj.FollowUpThrough = this.users[i].PrimaryEmail;
                        break;
                    }
                }
            }
            else if (this.obj.FollowUpProcess == "Secondary Email") {
                for (let i = 0; i < this.users.length; i++) {
                    if (this.users[i].Id == this.obj.UserId) {
                        this.obj.FollowUpThrough = this.users[i].SecondaryEmail;
                        break;
                    }
                }
            }
            else if (this.obj.FollowUpProcess == "Primary Phone") {
                for (let i = 0; i < this.users.length; i++) {
                    if (this.users[i].Id == this.obj.UserId) {
                        this.obj.FollowUpThrough = this.users[i].PrimaryContactNo;
                        break;
                    }
                }
            }
            else if (this.obj.FollowUpProcess == "Secondary Phone") {
                for (let i = 0; i < this.users.length; i++) {
                    if (this.users[i].Id == this.obj.UserId) {
                        this.obj.FollowUpThrough = this.users[i].SecondaryContactNo;
                        break;
                    }
                }
            }
            else {
                for (let i = 0; i < this.socialMedia.length; i++) {
                    if (this.socialMedia[i].AppName == this.obj.FollowUpProcess) {
                        this.obj.FollowUpThrough = this.socialMedia[i].Url;
                        break;
                    }
                }
            }

        }
    }
}