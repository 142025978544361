﻿import { Component, Output, EventEmitter, ViewChild, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { PagerService } from '../..//./services/pager.service';
import { HttpService } from '../..//./services/http.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserAddressModel } from "./..//../models/user-address.model";

@Component({
    selector: 'user-address',
    templateUrl: './user-address.component.html',
    providers: [PagerService, HttpService]
})
export class UserAddressComponent implements OnInit, OnChanges {
    @ViewChild('addressForm') public myForm: NgForm;
    @Input("user-id") UserId: number = 0;
    @Input("create") create: number = 0;
    id: number = 0;
    isDelete: boolean = false;
    searchString: string = "";
    isAddEdit: boolean = false;
    pagerSize: number = 10;
    start: number = 1;
    end: number = this.pagerSize + this.start - 1;
    data: any[] = [];
    keys: any[] = [];
    pageNo: number = 1;
    pager: any = {};
    dataCount: number = 0;
    pagerSizes: any[] = [
        { "Text": "5", "Value": 5 }, { "Text": "10", "Value": 10 }, { "Text": "25", "Value": 25 }, { "Text": "50", "Value": 50 }, { "Text": "All", "Value": 0 }
    ];
    orderBy: any = "Id";
    isDescending: boolean = true;
    isLoader: boolean = false;

    columns: any = [];
    api: any = { Search: "UserAddress/Search", Find: "UserAddress/Find", Delete: "UserAddress/Delete", Save: "UserAddress/Save" }
    permission: any = { Add: true, Edit: true, Delete: true };

    obj: UserAddressModel = new UserAddressModel();
    isSubmitted: boolean = false;
    isSaveLoader: boolean = false;
    constructor(public http: HttpService, public pagerService: PagerService, public toastr: ToastrService) {
        this.columns = [
            { Name: "AddressType", Header: "Type", DataType: "string" },
            { Name: "AddressLineOne", Header: "Address Line One", DataType: "string" },
            { Name: "AddressLineOne", Header: "Address Line Two", DataType: "string" },
            { Name: "City", Header: "City", DataType: "string" },
            { Name: "State", Header: "Province", DataType: "string" },
            { Name: "Country", Header: "Country", DataType: "string" },
            { Name: "PostalCode", Header: "Zip", DataType: "string" }
        ];
        this.isDelete = false;
        this.isAddEdit = false;
        this.id = 0;
        if (this.myForm) {
            this.myForm.reset();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.create.currentValue != changes.create.previousValue && parseInt(changes.create.currentValue) > 0) {
            this.edit(0);
        }
    }

    ngOnInit(): void {
        if (!this.columns) {
            this.columns = [];
        }
        this.loadData();
    }

    changePager(): void {
        this.pageNo = 1;
        this.loadData();
    }

    loadData(): void {
        this.start = (this.pageNo - 1) * this.pagerSize;
        this.end = this.pageNo * this.pagerSize;
        let objSearch = {
            SearchString: this.searchString, RecordsPerPage: this.pagerSize, OrderBy: this.orderBy, IsDecending: this.isDescending, PageNo: this.pageNo, Id: this.UserId
        };
        this.isLoader = true;
        this.http.post("" + this.api.Search, objSearch).subscribe(result => {
            this.data = result.json().List;
            this.dataCount = result.json().NoOfRecords;
            this.pager = this.pagerService.getPager(this.dataCount, this.pageNo, this.pagerSize);
            this.isLoader = false;

        }, error => {
            this.isLoader = false;
            this.http.validateAuth(error);
        });

    }


    sort(column: string) {
        if (this.orderBy == column) {
            this.isDescending = !this.isDescending;
        }
        else {
            this.isDescending = false;
        }
        this.orderBy = column;
        this.pageNo = 1;
        this.loadData();
    }

    setPage(page: number) {
        this.pageNo = page;
        this.loadData();

    }

    search() {
        this.pageNo = 1;
        this.loadData();
    }

    edit(Id: number) {
        this.find(Id);
        this.isAddEdit = true;
    }
    deleteConfirm(Id: number) {
        this.id = Id;
        this.isDelete = true;
    }
    delete() {
        this.http.post("" + this.api.Delete, { "Id": this.id }).subscribe(result => {
            this.closeModal();
            this.loadData();
        }, error => {
            console.error(error);
        });
    }

    closeModal() {
        this.isDelete = false;
        this.isAddEdit = false;
        this.id = 0;
    }
    clear() {
        this.isSubmitted = false;
        this.isSaveLoader = false;
        if (this.myForm) {
            this.myForm.reset();
        }
        this.obj = new UserAddressModel();
    }

    find(Id: number) {
        this.obj = new UserAddressModel();
        this.obj.Id = Id;
        this.obj.UserId = this.UserId;
        if (Id > 0) {
            this.http.post("" + this.api.Find, { Id: Id }).subscribe(result => {
                if (!result.json().IsFailure) {
                    this.obj = result.json();
                }
                this.isLoader = false;
            }, error => {
                this.isLoader = false;
                this.http.validateAuth(error);
            });
        }
    }

    save() {
        this.isSubmitted = true;
        if (this.myForm.valid) {
            this.isSaveLoader = true;
            this.obj.UserId = this.UserId;
            this.http.post("" + this.api.Save, this.obj).subscribe(result => {
                if (result.json().IsFailure) {
                    this.toastr.error('Failed!', result.json().Message);
                }
                else {
                    this.toastr.success('Success!', result.json().Message);
                    //this.clear();
                    this.loadData();
                    this.closeModal();
                }
                this.isSaveLoader = false;
            }, error => {
                this.isSaveLoader = false;
                this.http.validateAuth(error);
            });
        }
    }
}