import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgApexchartsModule } from "ng-apexcharts";

import { AppComponent } from './components/app/app.component';
import { HeaderComponent } from './components/header/header.component';
import { LeftMenuComponent } from './components/navigation/left-menu.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UsersComponent } from './components/user/users.component';
import { UserSaveComponent } from './components/user/user-save.component';
import { UserAddressComponent } from './components/user/user-address.component';
import { UserSocialMediaComponent } from './components/user/user-social-media.component';
import { UserOccupationComponent } from './components/user/user-occupation.component';
import { UpdatePasswordComponent } from './components/password/update-password.component';
import { ResetPasswordComponent } from './components/password/reset-password.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

import { LeadsComponent } from './components/lead/leads.component';
import { LeadSaveComponent } from './components/lead/lead-save.component';
import { FollowupsComponent } from './components/follow-up/followups.component';
import { FollowupSaveComponent } from './components/follow-up/followup-save.component';
import { UserFollowupComponent } from './components/follow-up/user-followup.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { MailComponent } from './components/mail/mail.component';
import { BulkMailComponent } from './components/mail/bulk-mail.component';
import { MailsComponent } from './components/mail/mails.component';
import { ClientsComponent } from './components/client/clients.component';
import { ClientSaveComponent } from './components/client/client-save.component';
import { DonutChartComponent } from './components/charts/donut-chart.component';
import { ColumnChartComponent } from './components/charts/column-chart.component';
import { PolarChartComponent } from './components/charts/polar-chart.component';
import { PieChartComponent } from './components/charts/pie-chart.component';

const routes: Routes = [
    { path: '', component: DashboardComponent },
    { path: 'users', component: UsersComponent },
    { path: 'user-save', component: UserSaveComponent },
    { path: 'leads', component: LeadsComponent },
    { path: 'lead-save', component: LeadSaveComponent },
    { path: 'update-password', component: UpdatePasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'follow-ups', component: FollowupsComponent },
    { path: 'follow-up-save', component: FollowupSaveComponent },
    { path: 'mail', component: MailComponent },
    { path: 'bulk-mail', component: BulkMailComponent },
    { path: 'mails', component: MailsComponent },
    { path: 'clients', component: ClientsComponent },
    { path: 'client-save', component: ClientSaveComponent },
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: '**', redirectTo: '' }
];



@NgModule({
  declarations: [
      AppComponent,
      HeaderComponent,
      LeftMenuComponent,
      DatatableComponent,
      LoginComponent,
      DashboardComponent,
      UserSaveComponent,
      UsersComponent,
      UserAddressComponent,
      UserSocialMediaComponent,
      UserOccupationComponent,
      UpdatePasswordComponent,
      ResetPasswordComponent,
      UnauthorizedComponent,
      LeadsComponent,
      LeadSaveComponent,
      FollowupsComponent,
      FollowupSaveComponent,
      UserFollowupComponent,
      FileUploaderComponent,
      MailComponent,
      MailsComponent,
      BulkMailComponent,
      ClientsComponent,
      ClientSaveComponent,
      DonutChartComponent,
      ColumnChartComponent,
      PolarChartComponent,
      PieChartComponent
  ],
  imports: [
      BrowserModule,
      HttpModule,
      FormsModule,
      RouterModule.forRoot(routes),
      BrowserAnimationsModule,
      OwlDateTimeModule,
      OwlNativeDateTimeModule,
      NgApexchartsModule,
      ToastrModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
