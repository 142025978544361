﻿import { Component, Inject, Injectable, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PagerService } from '../..//./services/pager.service';
import { HttpService } from '../..//./services/http.service';
import { Router } from "@angular/router";
import { PermissionModel } from "./..//../models/permission.model";
import { FileUploaderModel } from "./..//../models/file-upload.model";
import { ToastrService } from 'ngx-toastr';

function _window(): any {
    return window;
}

@Component({
    selector: 'data-table',
    templateUrl: './datatable.component.html',
    providers: [PagerService, HttpService]
})


export class DatatableComponent implements OnInit {
    @Input('header') header: string | "";
    @Input('columns') columns: any[] = [];
    @Input('type') type: string = "";
    @Input('api') api: any | { Search: "", Edit: "", Delete: "", SendMail: "", Upload: "", UploadTemplate: "" };
    @Output('editid') editid: EventEmitter<number> = new EventEmitter<number>();
    id: number = 0;
    isDelete: boolean = false;
    isUpload: boolean = false;
    searchString: string = "";
    permission: PermissionModel = new PermissionModel();

    pagerSize: number = 10;
    start: number = 1;
    end: number = this.pagerSize + this.start - 1;
    data: any[] = [];
    keys: any[] = [];
    pageNo: number = 1;
    pager: any = {};
    dataCount: number = 0;
    pagerSizes: any[] = [
        { "Text": "5", "Value": 5 }, { "Text": "10", "Value": 10 }, { "Text": "25", "Value": 25 }, { "Text": "50", "Value": 50 }, { "Text": "All", "Value": 0 }
    ];
    orderBy: any = "Id";
    isDescending: boolean = true;
    isLoader: boolean = false;
    
    
    constructor(public http: HttpService, public pagerService: PagerService, public router: Router, public toastr: ToastrService) {
        this.window.id = 0;
        this.isDelete = false;
        this.id = 0;
    }

    get window(): any {
        return _window();
    }

    ngOnInit(): void {
        if (!this.columns)
        {
            this.columns = [];
        }
        this.loadData();
    }

    changePager(): void {
        this.pageNo = 1;
        this.loadData();
    }

    loadData(): void {
        this.start = (this.pageNo - 1) * this.pagerSize;
        this.end = this.pageNo * this.pagerSize;
        let objSearch = {
            SearchString: this.searchString, RecordsPerPage: this.pagerSize, OrderBy: this.orderBy, IsDecending: this.isDescending, PageNo: this.pageNo, Message: this.type
        };
        this.isLoader = true;
        this.http.post("" + this.api.Search, objSearch).subscribe(result => {
            this.permission = result.json().Permissions;
            this.isLoader = false;
            if (this.permission.isView) {
                this.data = result.json().List;
                this.dataCount = result.json().NoOfRecords;
                this.pager = this.pagerService.getPager(this.dataCount, this.pageNo, this.pagerSize);
                this.isLoader = false;
            }
            else {
                this.router.navigateByUrl("/unauthorized");
            }
            
        }, error => {
            this.isLoader = false;
            this.http.validateAuth(error);
        });

    }
    

    sort(column: string) {
        if (this.orderBy == column) {
            this.isDescending = !this.isDescending;
        }
        else {
            this.isDescending = false;
        }
        this.orderBy = column;
        this.pageNo = 1;
        this.loadData();
    }

    setPage(page: number) {
        this.pageNo = page;
        this.loadData();

    }

    search() {
        this.pageNo = 1;
        this.loadData();
    }

    edit(Id: number) {
        if (this.api.Edit != "")
        {
            this.window.id = Id;
            this.router.navigateByUrl("/" + this.api.Edit);
        }
        else {
            this.editid.emit(Id);
        }
    }
    deleteConfirm(Id: number) {
        this.id = Id;
        this.isDelete = true;
    }
    delete() {
        this.http.post("" + this.api.Delete, { "Id": this.id }).subscribe(result => {
            this.closeModal();
            this.loadData();
        }, error => {
            console.error(error);
        });
    }

    send(Id: number) {
        this.http.post("" + this.api.SendMail, { "Id": Id }).subscribe(result => {
            this.loadData();
        }, error => {
            console.error(error);
        });
    }

    closeModal() {
        this.isDelete = false;
        this.id = 0;
    }

    file: FileUploaderModel = new FileUploaderModel();
    selectFile(file: FileUploaderModel) {
        this.file = file;
    }
    upload() {
        this.isUpload = true;
        this.file = new FileUploaderModel(); 
    }
    closeUpload() {
        this.isUpload = false;
        this.file = new FileUploaderModel();
    }
    isUploadLoader: boolean = false;
    uploadConfirm() {
        if (this.file.IsValid && this.file.Name != "")
        {
            this.isUploadLoader = true;
            let formData = new FormData();
            formData.append("users", this.file.File);
            this.http.postFile("" + this.api.Upload, formData).subscribe(result => {
                if (result.json().IsFailure) {
                    this.toastr.error('Failed!', result.json().Message);
                }
                else {
                    this.toastr.success('Success!', result.json().Message);
                    this.closeUpload();
                }
                this.isUploadLoader = false;
            }, error => {
                console.error(error);
                this.isUploadLoader = false;
                this.http.validateAuth(error);
            });
        }
    }
}

