﻿import { Component, ViewChild } from '@angular/core';
import { HttpService } from '../..//./services/http.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UpdatePasswordModel } from "./..//../models/update-password.model";

@Component({
    selector: 'update-password',
    templateUrl: './update-password.component.html',
    providers: [HttpService]
})
export class UpdatePasswordComponent {
    @ViewChild('myForm') public myForm: NgForm;
    api = { "Update": "auth/updatepassword" };
    obj: UpdatePasswordModel = new UpdatePasswordModel();
    isSubmitted: boolean = false;
    isLoader: boolean = false;
    header: string = "Update Password";

    constructor(public http: HttpService, public toastr: ToastrService) {
        if (this.myForm) {
            this.myForm.reset();
        }
    }
    update() {
        this.isSubmitted = true;
        if (this.myForm.valid && this.obj.NewPassword == this.obj.ConfirmPassword) {
            this.isLoader = true;
            this.http.post("" + this.api.Update, this.obj).subscribe(result => {
                if (result.json().IsFailure) {
                    this.toastr.error('Failed!', result.json().Message);
                }
                else {
                    this.toastr.success('Success!', result.json().Message);
                    this.clear();
                }
                this.isLoader = false;
            }, error => {
                this.isLoader = false;
                this.http.validateAuth(error);
            });
        }
    }

    clear() {
        this.isSubmitted = false;
        this.isLoader = false;
        this.obj = new UpdatePasswordModel();
        if (this.myForm) {
            this.myForm.reset();
        }
    }
}