﻿export class FollowupModel
{
    Id: number = 0;
    UserId: number = 0;
    FullName: string = "";
    FollowUpProcess: string = "";
    FollowUpThrough: string = "";
    FollowUpDate: string = "";
    FollowUpNote: string = "";
    FollowUpStatus: string = "";
    FollowUpRemarks: string = "";
    IsConverted: boolean = false;
    ContactStatus: string = "";
    UserStatus: string = "";
}