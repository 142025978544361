﻿export class EmailModel{
    To: EmailReceipentModel = new EmailReceipentModel();
    Subject: string = "";
    Name: string = "";
    Email: string = "";
    Body: string = "";
    FollowupId: number = 0;
}

export class BulkEmailModel {
    To: EmailReceipentModel[] = new Array<EmailReceipentModel>();
    Subject: string = "";
    Body: string = "";
    FollowupId: number = 0;
}

export class EmailReceipentModel {
    Name: string = "";
    Email: string = "";
    IsChanged: boolean = false;
}

