﻿export class PieChartModel{
    labels: string[] = new Array<string>();
    series: number[] = new Array<number>();
}
export class PolarChartModel {
    series: number[] = new Array<number>();
}
export class ColumnChartModel {
    text: string = "";
    categories: string[] = new Array<string>();
    series: ColumnChartSeriesModel[] = new Array<ColumnChartSeriesModel>();
}
export class ColumnChartSeriesModel {
    name: string = "";
    data: number[] = new Array<number>();
}