import { Component } from '@angular/core';
import { HttpService } from '../../services/http.service';
declare var getLocalStorage: any;
declare var setLocalStorage: any;
function _window(): any {
    return window;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [HttpService]
})
export class AppComponent {
    isAuth: number = 0;
    authKey: string = "";
    menu: any = [];
    constructor(public http: HttpService) {
        this.validateAuth();
    }
    get window(): any {
        return _window();
    }
    validateAuth() {
        if (!getLocalStorage("_rfcc") || getLocalStorage("_rfcc") == null || getLocalStorage("_rfcc") == "") {
            this.isAuth = -1;
        }
        else {
            this.http.post("auth/validateauth").subscribe(result => {
                
                if (!result.json().IsFailure) {
                    this.menu = result.json().Data.Permissions;
                    setLocalStorage("_rfcc", result.json().Data.Message);
                    setLocalStorage("_rfnm", result.json().Data.NickName);
                    this.window.name = result.json().Data.Role;
                    this.isAuth = 1;
                }
                else {
                    setLocalStorage("_rfcc", "");
                    setLocalStorage("_rfnm", "");
                    this.isAuth = -1;
                    this.window.name = "";
                }
            }, error => {
                setLocalStorage("_rfcc", "");
                setLocalStorage("_rfnm", "");
                this.window.name = "";
                this.isAuth = -1;
            });
        }
    }

    changeAuth(evt: boolean)
    {
        if (evt)
        {
            this.isAuth = 1;
        }
        else {
            this.isAuth = -1;
        }
    }

    loadMenu(menu: any) {
        this.isAuth = 1;
        this.menu = menu;
    }
}
