﻿import { Component, ViewChild, OnInit, Input } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { PieChartModel } from "./..//../models/analytics.model";

import {
    ApexNonAxisChartSeries,
    ApexResponsive,
    ApexChart,
    ApexStroke,
    ApexFill
} from "ng-apexcharts";

export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
    labels: any;
    stroke: ApexStroke;
    fill: ApexFill;
};

@Component({
    selector: "polar-chart",
    templateUrl: "./polar-chart.component.html"
})
export class PolarChartComponent {
    @ViewChild("polarchart") chart: ChartComponent;
    @Input("data") data: PieChartModel = new PieChartModel();
    public chartOptions: Partial<ChartOptions>;

    constructor() {

    }

    ngOnInit() {
        this.chartOptions = {
            series: this.data.series,
            chart: {
                type: "polarArea"
            },
            stroke: {
                colors: ["#fff"]
            },
            labels: this.data.labels,
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                }
            ]
        };
    }
}
