﻿import { Component, Inject, Injectable, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FileUploaderModel } from "./..//../models/file-upload.model";



@Component({
    selector: 'file-uploader',
    templateUrl: './file-uploader.component.html'
})
export class FileUploaderComponent implements OnChanges {
    @Input("file-name") fileName: string = "";
    @Input("allowed-extensions") allowedExtensions: string = "";
    @Input("control-name") controlName: string = "";
    @Output("selectedfile") selectedfile: EventEmitter<FileUploaderModel> = new EventEmitter<FileUploaderModel>();

    File: FileUploaderModel = {
        File: null, Name: this.fileName, Extension: "", Size: 0, IsValid: true, IsChanged: false
    };

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.fileName.firstChange) {
            this.File = {
                File: null, Name: this.fileName, Extension: "", Size: 0, IsValid: true, IsChanged: false
            };
        }
        else {
            this.File = {
                File: null, Name: this.fileName, Extension: "", Size: 0, IsValid: true, IsChanged: false
            };
        }


    }

    selectfile(files: FileList) {
        if (files.length > 0) {
            let file = files.item(0);
            let extension = "." + file.name.split('.')[file.name.split('.').length - 1];
            let isValid = this.validate(extension);
            if (isValid) {
                this.File = {
                    File: file, Name: file.name, Extension: extension, Size: file.size, IsValid: isValid, IsChanged: true
                };
            }
            else {
                this.File = {
                    File: null, Name: "", Extension: extension, Size: 0, IsValid: isValid, IsChanged: true
                };
            }
        }
        this.selectedfile.emit(this.File);
    }

    validate(extension: string): boolean {
        let isValid = false;
        if (this.allowedExtensions != "") {
            if (this.allowedExtensions.toString().toLowerCase().indexOf(extension.toLowerCase()) > -1) {
                isValid = true;
            }
        }
        else {
            isValid = true;
        }
        return isValid;
    }

}
