﻿import { Component, ViewChild, OnInit } from '@angular/core';
import { PagerService } from '../..//./services/pager.service';
import { HttpService } from '../..//./services/http.service';
import { PermissionModel } from "./..//../models/permission.model";
import { Router } from "@angular/router";
function _window(): any {
    return window;
}
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UpdatePasswordModel } from "./..//../models/update-password.model";

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    providers: [HttpService, PagerService]
})
export class ResetPasswordComponent implements OnInit{
    @ViewChild('resetPassForm') public myForm: NgForm;
    api = { Search: "userdetail/search","Reset": "auth/resetpassword" };
    obj: UpdatePasswordModel = new UpdatePasswordModel();
    isSubmitted: boolean = false;
    isLoader: boolean = false;
    header: string = "Reset Password";
    columns: any;
    type: string = "";
    get window(): any {
        return _window();
    }
    constructor(public http: HttpService, public pagerService: PagerService, public router: Router, public toastr: ToastrService) {
        if (this.myForm) {
            this.myForm.reset();
        }
        this.columns = [
            { Name: "FullName", Header: "Name", DataType: "string" },
            { Name: "PrimaryContactNo", Header: "Contact No", DataType: "string" },
            { Name: "PrimaryEmail", Header: "Email", DataType: "string" },
            { Name: "UserStatus", Header: "Type", DataType: "string" },
            { Name: "Gender", Header: "Gender", DataType: "string" },
            { Name: "Occupation", Header: "Occupation", DataType: "string" },
            { Name: "Role", Header: "Role", DataType: "string" }
        ];
        if (window.name == "Admin" || window.name == "Super Admin")
        {

        }
        else {
            this.router.navigateByUrl("/unauthorized");
        }
    }
    ngOnInit(): void {
        this.loadData();
    }
    searchString: string = "";
    permission: PermissionModel = new PermissionModel();
    isReset: boolean = false;
    pagerSize: number = 10;
    start: number = 1;
    end: number = this.pagerSize + this.start - 1;
    data: any[] = [];
    keys: any[] = [];
    pageNo: number = 1;
    pager: any = {};
    dataCount: number = 0;
    pagerSizes: any[] = [
        { "Text": "5", "Value": 5 }, { "Text": "10", "Value": 10 }, { "Text": "25", "Value": 25 }, { "Text": "50", "Value": 50 }, { "Text": "All", "Value": 0 }
    ];
    orderBy: any = "Id";
    isDescending: boolean = true;
    changePager(): void {
        this.pageNo = 1;
        this.loadData();
    }

    loadData(): void {
        this.start = (this.pageNo - 1) * this.pagerSize;
        this.end = this.pageNo * this.pagerSize;
        let objSearch = {
            SearchString: this.searchString, RecordsPerPage: this.pagerSize, OrderBy: this.orderBy, IsDecending: this.isDescending, PageNo: this.pageNo, Message: this.type
        };
        this.isLoader = true;
        this.http.post("" + this.api.Search, objSearch).subscribe(result => {
            this.isLoader = false;
            this.data = result.json().List;
            this.dataCount = result.json().NoOfRecords;
            this.pager = this.pagerService.getPager(this.dataCount, this.pageNo, this.pagerSize);
            this.isLoader = false;

        }, error => {
            this.isLoader = false;
            this.http.validateAuth(error);
        });

    }

    sort(column: string) {
        if (this.orderBy == column) {
            this.isDescending = !this.isDescending;
        }
        else {
            this.isDescending = false;
        }
        this.orderBy = column;
        this.pageNo = 1;
        this.loadData();
    }

    setPage(page: number) {
        this.pageNo = page;
        this.loadData();

    }

    search() {
        this.pageNo = 1;
        this.loadData();
    }
    edit(Id:number) {
        this.obj.Id = Id;
        this.isReset = true;
    }
    reset() {
        this.isSubmitted = true;
        if (this.myForm.valid) {
            this.isLoader = true;
            this.http.post("" + this.api.Reset, this.obj).subscribe(result => {
                if (result.json().IsFailure) {
                    this.toastr.error('Failed!', result.json().Message);
                }
                else {
                    this.toastr.success('Success!', result.json().Message);
                    this.clear();
                }
                this.isLoader = false;
            }, error => {
                this.isLoader = false;
                this.http.validateAuth(error);
            });
        }
    }

    clear() {
        this.isSubmitted = false;
        this.isLoader = false;
        this.obj = new UpdatePasswordModel();
        if (this.myForm) {
            this.myForm.reset();
        }
    }
}