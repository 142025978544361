﻿import { Component, OnInit } from '@angular/core';
import { PagerService } from '../..//./services/pager.service';
import { HttpService } from '../..//./services/http.service';
import { PermissionModel } from "./..//../models/permission.model";
import { Router } from "@angular/router";
function _window(): any {
    return window;
}

@Component({
    selector: 'follow-ups',
    templateUrl: './followups.component.html',
    providers: [PagerService, HttpService]
})
export class FollowupsComponent implements OnInit {
    api: any = { Search: "followup/search", Delete: "followup/delete", Edit: "/follow-up-save" };
    columns: any;
    header: string = "Follow Ups";
    isLoader: boolean = false;
    type: string = "";
    constructor(public http: HttpService, public pagerService: PagerService, public router: Router) {
        this.columns = [
            { Name: "FullName", Header: "Name", DataType: "string" },
            { Name: "FollowUpProcess", Header: "Through", DataType: "string" },
            { Name: "FollowUpThrough", Header: "Connect", DataType: "string" },
            { Name: "FollowUpNote", Header: "Note", DataType: "string" },
            { Name: "FollowUpDate", Header: "Date", DataType: "string" },
            { Name: "FollowUpStatus", Header: "Status", DataType: "string" },
            { Name: "FollowUpRemarks", Header: "Remarks", DataType: "string" }
        ];
        this.isLoader = true;
    }

    ngOnInit(): void {
        this.loadData();
    }
    get window(): any {
        return _window();
    }

    //datatable
    id: number = 0;
    isDelete: boolean = false;
    searchString: string = "";
    permission: PermissionModel = new PermissionModel();

    pagerSize: number = 10;
    start: number = 1;
    end: number = this.pagerSize + this.start - 1;
    data: any[] = [];
    keys: any[] = [];
    pageNo: number = 1;
    pager: any = {};
    dataCount: number = 0;
    pagerSizes: any[] = [
        { "Text": "5", "Value": 5 }, { "Text": "10", "Value": 10 }, { "Text": "25", "Value": 25 }, { "Text": "50", "Value": 50 }, { "Text": "All", "Value": 0 }
    ];
    orderBy: any = "Id";
    isDescending: boolean = true;
    changePager(): void {
        this.pageNo = 1;
        this.loadData();
    }

    loadData(): void {
        this.start = (this.pageNo - 1) * this.pagerSize;
        this.end = this.pageNo * this.pagerSize;
        let objSearch = {
            SearchString: this.searchString, RecordsPerPage: this.pagerSize, OrderBy: this.orderBy, IsDecending: this.isDescending, PageNo: this.pageNo, Message: this.type
        };
        this.isLoader = true;
        this.http.post("" + this.api.Search, objSearch).subscribe(result => {
            this.permission = result.json().Permissions;
            this.isLoader = false;
            if (this.permission.isView) {
                this.data = result.json().List;
                this.dataCount = result.json().NoOfRecords;
                this.pager = this.pagerService.getPager(this.dataCount, this.pageNo, this.pagerSize);
                this.isLoader = false;
            }
            else {
                this.router.navigateByUrl("/unauthorized");
            }

        }, error => {
            this.isLoader = false;
            this.http.validateAuth(error);
        });

    }


    sort(column: string) {
        if (this.orderBy == column) {
            this.isDescending = !this.isDescending;
        }
        else {
            this.isDescending = false;
        }
        this.orderBy = column;
        this.pageNo = 1;
        this.loadData();
    }

    setPage(page: number) {
        this.pageNo = page;
        this.loadData();

    }

    search() {
        this.pageNo = 1;
        this.loadData();
    }

    edit(Id: number) {
        this.window.id = Id;
        this.router.navigateByUrl("/" + this.api.Edit);
    }
    deleteConfirm(Id: number) {
        this.id = Id;
        this.isDelete = true;
    }
    delete() {
        this.http.post("" + this.api.Delete, { "Id": this.id }).subscribe(result => {
            this.closeModal();
            this.loadData();
        }, error => {
            console.error(error);
        });
    }

    send(Id: number) {
        this.http.post("" + this.api.SendMail, { "Id": Id }).subscribe(result => {
            this.loadData();
        }, error => {
            console.error(error);
        });
    }

    closeModal() {
        this.isDelete = false;
        this.id = 0;
    }

    mail(email: string, name: string, id:number) {
        this.window.email = email;
        this.window.followup_id = id;
        this.router.navigateByUrl("/mail");
    }
}