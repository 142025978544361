﻿import { Injectable } from '@angular/core';
import { Http, Headers, ResponseContentType } from '@angular/http';
declare var getLocalStorage: any;
declare var setLocalStorage: any;
function _window(): any {
    return window;
}

@Injectable()
export class HttpService {
    authKey: string = "";
    constructor(private http: Http) {
        this.authKey = getLocalStorage("_rfcc");
    }
    createAuthorizationHeader(headers: Headers) {
        headers.append('AuthKey', this.authKey);
    }

    get(url: string) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);
        return this.http.get("/api/" + url, {
            headers: headers
        });

    }
    

    post(url: string, data: any = null) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);
        return this.http.post("/api/" + url, data, {
            headers: headers
        });
    }

    postFile(url: string, data: FormData = null) {
        let headers = new Headers();
        this.createAuthorizationHeader(headers);

        return this.http.post("/api/" + url, data, {
            headers: headers
        });

    }
    get window(): any {
        return _window();
    }
    validateAuth(err: any)
    {
        try {
            if (err.status == 401) {
                setLocalStorage("_rfcc", "");
                window.location.href = "/";
            }
        }
        catch(e) { }
    }

}