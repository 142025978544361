﻿import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { LoginModel, ForgotPasswordModel } from '../../models/login.model';
import { NgForm } from '@angular/forms';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
declare var getLocalStorage: any;
declare var setLocalStorage: any;
function _window(): any {
    return window;
}
@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [HttpService]
})
export class LoginComponent {
    @ViewChild('myForm') public myForm: NgForm;
    @Output('menu') menu: EventEmitter<any> = new EventEmitter<any>();
    loginModel: LoginModel = new LoginModel();
    passwordModel: ForgotPasswordModel = new ForgotPasswordModel();
    isSubmitted: boolean = false;
    isLoader: boolean = false;
    IsFailure: boolean = false;
    Message: string = "";
    constructor(public http: HttpService, public router: Router, public toastr: ToastrService) {
        
        if (this.myForm) {
            this.myForm.reset();
        }
    }

    get window(): any {
        return _window();
    }
    signin() {
        this.isSubmitted = true;
        if (this.myForm.valid) {
            this.isLoader = true;
            this.http.post("auth/login", this.loginModel).subscribe(result => {
                this.IsFailure = result.json().IsFailure;
                if (!result.json().IsFailure) {
                    setLocalStorage("_rfcc", result.json().Message);
                    setLocalStorage("_rfnm", result.json().NickName);
                    this.window.name = result.json().Role;
                    this.toastr.success('Success!', "Login Successful!");
                    this.menu.emit(result.json().Permissions);
                }
                else {
                    this.Message = result.json().Message;
                    this.toastr.error('Failed!', result.json().Message);
                    this.window.name = "";
                    setLocalStorage("_rfnm", "");
                    setLocalStorage("_rfcc", "");
                }
                this.isLoader = false;
            }, error => {
                this.http.validateAuth(error);
                this.isLoader = false;
            });
        }
    }

    isForgotpass: boolean = false;
    forgotPassword() {
        this.isSubmitted = true;
        if (this.myForm.valid) {
            this.isLoader = true;
            this.http.post("auth/forgotpassword", this.passwordModel).subscribe(result => {
                this.IsFailure = result.json().IsFailure;
                if (!result.json().IsFailure) {
                    this.toastr.success('Success!', "New password has been send to your email address!");
                    this.isForgotpass = false;
                }
                else {
                    this.toastr.error('Failed!', result.json().Message);
                }
                this.isLoader = false;
            }, error => {
                this.http.validateAuth(error);
                this.isLoader = false;
            });
        }
    }
}