﻿import { Component, Input, OnInit } from '@angular/core';
import { HttpService } from '../../services/http.service';
function _window(): any {
    return window;
}
@Component({
    selector: 'left-menu',
    templateUrl: './left-menu.component.html'
})
export class LeftMenuComponent implements OnInit {
    @Input('menu') menu: any = [];
    get window(): any {
        return _window();
    }

    constructor(public http: HttpService) {
    }

    ngOnInit() {
        if (this.menu.length <= 0)
        {
            this.loadMenu();
        }
    }

    loadMenu() {
        this.http.post("auth/validateauth").subscribe(result => {
            if (!result.json().IsFailure) {
                this.menu = result.json().Data.Permissions;
            }
        }, error => {
            
        });
    }
}