﻿import { Component } from '@angular/core';

@Component({
    selector: 'clients',
    templateUrl: './clients.component.html'
})
export class ClientsComponent {
    api: any = { Search: "userdetail/search", Delete: "userdetail/delete", Edit: "/client-save"};
    columns: any;
    header: string = "Clients";
    isLoad: boolean = false;
    type: string = "Client";
    constructor() {
        this.columns = [
            { Name: "FullName", Header: "Name", DataType: "string" },
            { Name: "PrimaryContactNo", Header: "Contact No", DataType: "string" },
            { Name: "PrimaryEmail", Header: "Email", DataType: "string" },
            { Name: "UserStatus", Header: "Type", DataType: "string" },
            { Name: "Gender", Header: "Gender", DataType: "string" },
            { Name: "strConversionDate", Header: "Conversion Date", DataType: "string" },
            { Name: "Occupation", Header: "Occupation", DataType: "string" }
        ];
        this.isLoad = true;
    }
    
}