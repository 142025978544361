﻿export class UserOccupationModel {
    Id: number = 0;
    UserId: number = 0;
    OccupationType: string = "";
    Designation: string = "";
    OrgName: string = "";
    Email: string = "";
    PrimaryContactNo: string = "";
    SecondaryContactNo: string = "";
    DateOfJoining ?: string = "";
    DateOfSeperation: string = "";
    IsCurrent: boolean = false;
}