﻿export class UserModel{
    Id: number = 0;
    FirstName: string = "";
    MiddleName: string = "";
    LastName: string = "";
    PrimaryEmail: string = "";
    SecondaryEmail: string = "";
    PrimaryContactNo: string = "";
    SecondaryContactNo: string = "";
    DateOfBirth: string = "";
    Gender: string = "";
    Avatar: string = "";
    Bio: string = "";
    IsAdmin: boolean = false;
    FullName: string = "";
    Qualification: string = "";
    Occupation: string = "";
    UserStatus: string = "";
    LeadStatus: string = "";
    ContactStatus: string = "";
    Role: string = "";
    NickName: string = "";
    Nationality: string = "American";
    MarritalStatus: string = "";
}