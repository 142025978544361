﻿import { Component, ViewChild, OnInit, Input } from "@angular/core";
import { ColumnChartModel } from "./..//../models/analytics.model";
import {
    ApexAxisChartSeries,
    ApexChart,
    ChartComponent,
    ApexDataLabels,
    ApexPlotOptions,
    ApexYAxis,
    ApexLegend,
    ApexStroke,
    ApexXAxis,
    ApexFill,
    ApexTooltip
} from "ng-apexcharts";

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    fill: ApexFill;
    tooltip: ApexTooltip;
    stroke: ApexStroke;
    legend: ApexLegend;
};

@Component({
    selector: "column-chart",
    templateUrl: "./column-chart.component.html"
})
export class ColumnChartComponent implements OnInit {
    @ViewChild("columnchart") chart: ChartComponent;
    @Input("data") data: ColumnChartModel = new ColumnChartModel();
    public chartOptions: Partial<ChartOptions>;

    constructor() {
        
        
    }

    ngOnInit() {
        this.chartOptions = {
            series: this.data.series,
            chart: {
                type: "bar"
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded"
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"]
            },
            xaxis: {
                categories: this.data.categories
            },
            yaxis: {
                title: {
                    text: this.data.text
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val.toString();
                    }
                }
            }
        };
    }
}
