﻿import { Component, OnInit } from '@angular/core';
import { HttpService } from '../..//./services/http.service';
import { BulkEmailModel, EmailReceipentModel } from "./..//../models/mail.model";
import { ToastrService } from 'ngx-toastr';
function _window(): any {
    return window;
}
@Component({
    selector: 'bulk-mail',
    templateUrl: './bulk-mail.component.html',
    providers: [HttpService]
})
export class BulkMailComponent implements OnInit {
    api: any = { SendMail: "mail/bulksave", Emails:"userdetail/emails" };
    isLoader: boolean = false;
    email: string = "";
    obj: BulkEmailModel = new BulkEmailModel();
    isSubmitted: boolean = false;
    constructor(public http: HttpService, public toastr: ToastrService) {
        this.obj.To = new Array<EmailReceipentModel>();
        this.obj.To.push({ "Name": "", "Email": "", "IsChanged": false });
        this.window.email = "";
        this.window.followup_id = 0;
    }
    get window(): any {
        return _window();
    }
    ngOnInit(): void {
        this.loadEmails();
    }
    
    emails: any = [];
    loadEmails() {
        this.isLoader = true;
        this.http.post("" + this.api.Emails).subscribe(result => {
            if (!result.json().IsFailure) {
                this.emails = result.json();
            }
            this.isLoader = false;
        }, error => {
            this.isLoader = false;
            this.http.validateAuth(error);
        });
    }

    clear() {
        this.isSubmitted = false;
        this.obj = new BulkEmailModel();
        this.isPreview = false;
    }
    mailchange(index: number)
    {
        for (let i = 0; i < this.emails.length; i++) {
            if (this.emails[i].Value.toLowerCase() == this.obj.To[index].Email.toLowerCase()) {
                this.obj.To[index].Name = this.emails[i].Name;
            }
        }
    }

    addTo() {
        if (this.validateTo()) {
            this.obj.To.push({ "Name": "", "Email": "", "IsChanged":false });
        }
    }
    removeTo(index: number) {
        this.obj.To.splice(index,1);
    }

    validateTo() {
        let isValid = true;
        for (let i = 0; i < this.obj.To.length; i++) {
            if (this.obj.To[i].Email == '' || this.obj.To[i].Name == '') {
                isValid = false;
            }
            this.obj.To[i].IsChanged = true;
        }
        return isValid;
    }

    sendMail() {
        this.isSubmitted = true;
        if (!this.validateTo() || this.obj.Body == '' || this.obj.Subject == '')
        {
            return false;
        }
        else {
            for (let i = 0; i < this.emails.length; i++) {
                for (let index = 0; index < this.obj.To.length; index++) {
                    if (this.emails[i].Value.toLowerCase() == this.obj.To[index].Email.toLowerCase()) {
                        this.obj.To[index].Name = this.emails[i].Name;
                    }
                }
            }
            this.isLoader = true;
            this.http.post("" + this.api.SendMail, this.obj).subscribe(result => {
                if (result.json().IsFailure) {
                    this.toastr.error('Failed!', result.json().Message);
                }
                else {
                    this.toastr.success('Success!', "Successfully sent!");
                    this.clear();
                }
                this.isLoader = false;
            }, error => {
                this.isLoader = false;
                this.http.validateAuth(error);
            });
        }
    }
    isPreview: boolean = false;
    preview() {
        this.isSubmitted = true;
        if (!this.validateTo() || this.obj.Body == '' || this.obj.Subject == '') {
            return false;
        }
        for (let i = 0; i < this.emails.length; i++) {
            for (let index = 0; index < this.obj.To.length; index++) {
                if (this.emails[i].Value.toLowerCase() == this.obj.To[index].Email.toLowerCase()) {
                    this.obj.To[index].Name = this.emails[i].Name;
                }
            }
        }
        this.isPreview = true;
    }
}