﻿import { Component, OnInit } from '@angular/core';
import { PieChartModel, ColumnChartModel, ColumnChartSeriesModel, PolarChartModel } from "./..//../models/analytics.model";
import { HttpService } from '../..//./services/http.service';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    providers: [HttpService]
})

export class DashboardComponent implements OnInit {
    header: string = "Dashboard";
    isFollowupLoader: boolean = true;
    followupStatus: PieChartModel = new PieChartModel();
    isLeadLoader: boolean = true;
    leadStatus: PieChartModel = new PieChartModel();
    isMailLoader: boolean = true;
    mailStatus: PieChartModel = new PieChartModel();
    isConversionLoader: boolean = true;
    conversionStatus: ColumnChartModel = new ColumnChartModel();

    constructor(public http: HttpService) {

    }

    ngOnInit() {
        this.loadFollowupStatus();
        this.loadLeadStatus();
        this.loadMailStatus();
        this.loadConversionStatus();
    }

    loadFollowupStatus() {
        this.isFollowupLoader = true;
        this.http.post("analytics/followupstatus").subscribe(result => {
            this.followupStatus = result.json();
            this.isFollowupLoader = false;
        }, error => {
            this.isFollowupLoader = false;
            this.http.validateAuth(error);
        });
    }

    loadLeadStatus() {
        this.isLeadLoader = true;
        this.http.post("analytics/leadstatus").subscribe(result => {
            this.leadStatus = result.json();
            this.isLeadLoader = false;
        }, error => {
            this.isLeadLoader = false;
            this.http.validateAuth(error);
        });
    }
    loadMailStatus() {
        this.isMailLoader = true;
        this.http.post("analytics/YearlyMailSent").subscribe(result => {
            this.mailStatus = result.json();
            this.isMailLoader = false;
        }, error => {
            this.isMailLoader = false;
            this.http.validateAuth(error);
        });
    }

    loadConversionStatus() {
        this.isConversionLoader = true;
        this.http.post("analytics/ConversionStatus").subscribe(result => {
            this.conversionStatus = result.json();
            this.isConversionLoader = false;
        }, error => {
            this.isConversionLoader = false;
            this.http.validateAuth(error);
        });
    }
}