﻿import { Component, OnInit } from '@angular/core';
import { HttpService } from '../..//./services/http.service';
import { EmailModel, EmailReceipentModel } from "./..//../models/mail.model";
import { ToastrService } from 'ngx-toastr';
function _window(): any {
    return window;
}
@Component({
    selector: 'mail',
    templateUrl: './mail.component.html',
    providers: [HttpService]
})
export class MailComponent implements OnInit {
    api: any = { SendMail: "mail/save", Emails:"userdetail/emails" };
    isLoader: boolean = false;
    email: string = "";
    obj: EmailModel = new EmailModel();
    isSubmitted: boolean = false;
    constructor(public http: HttpService, public toastr: ToastrService) {
        this.obj.To = new EmailReceipentModel();
        if (this.window.email && this.window.email != null && this.window.email != '')
        {
            this.obj.Email = this.window.email;
            this.obj.FollowupId = parseInt(this.window.followup_id);
            this.obj.Subject = "Followup email";
        }
        else {
            this.obj.Email = "";
            this.obj.FollowupId = 0;
        }
        this.window.email = "";
        this.window.followup_id = 0;
    }
    get window(): any {
        return _window();
    }
    ngOnInit(): void {
        this.loadEmails();
    }
    
    emails: any = [];
    loadEmails() {
        this.isLoader = true;
        this.http.post("" + this.api.Emails).subscribe(result => {
            if (!result.json().IsFailure) {
                this.emails = result.json();
            }
            this.isLoader = false;
        }, error => {
            this.isLoader = false;
            this.http.validateAuth(error);
        });
    }

    clear() {
        this.isSubmitted = false;
        this.obj = new EmailModel();
        this.isPreview = false;
    }


    sendMail() {
        this.isSubmitted = true;
        if (this.obj.Email == '' || this.obj.Body == '' || this.obj.Subject == '')
        {
            return false;
        }
        else {
            for (let i = 0; i < this.emails.length; i++)
            {
                if (this.emails[i].Value.toLowerCase() == this.obj.Email.toLowerCase())
                {
                    this.obj.Name = this.emails[i].Name;
                }
            }
            this.isLoader = true;
            this.http.post("" + this.api.SendMail, this.obj).subscribe(result => {
                if (result.json().IsFailure) {
                    this.toastr.error('Failed!', result.json().Message);
                }
                else {
                    this.toastr.success('Success!', "Successfully sent!");
                    this.clear();
                }
                this.isLoader = false;
            }, error => {
                this.isLoader = false;
                this.http.validateAuth(error);
            });
        }
    }
    isPreview: boolean = false;
    preview() {
        this.isSubmitted = true;
        if (this.obj.Email == '' || this.obj.Body == '' || this.obj.Subject == '') {
            return false;
        }
        for (let i = 0; i < this.emails.length; i++) {
            if (this.emails[i].Value.toLowerCase() == this.obj.Email.toLowerCase()) {
                this.obj.Name = this.emails[i].Name;
            }
        }
        this.isPreview = true;
    }
}