﻿import { Component } from '@angular/core';

@Component({
    selector: 'leads',
    templateUrl: './leads.component.html'
})
export class LeadsComponent {
    api: any = { Search: "userdetail/search", Delete: "userdetail/delete", Edit: "/lead-save", Upload: "userdetail/upload", UploadTemplate: "/templates/upload/users.xlsx" };
    columns: any;
    header: string = "Leads";
    isLoad: boolean = false;
    type: string = "Lead";
    constructor() {
        this.columns = [
            { Name: "FullName", Header: "Name", DataType: "string" },
            { Name: "PrimaryContactNo", Header: "Contact No", DataType: "string" },
            { Name: "PrimaryEmail", Header: "Email", DataType: "string" },
            { Name: "UserStatus", Header: "Type", DataType: "string" },
            { Name: "Gender", Header: "Gender", DataType: "string" },
            { Name: "Qualification", Header: "Qualification", DataType: "string" },
            { Name: "Occupation", Header: "Occupation", DataType: "string" }
        ];
        this.isLoad = true;
    }
    
}